import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/codebuild/output/src624843067/src/lenny-s.com/src/components/DefaultTemplate.js";
import Bio from '../components/Bio';
import SEO from '../components/seo';
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={props} components={components}>


      <Bio />
      <SEO title="Resume" keywords={[]} />
      <MDXTag name="h2" components={components}>{`Work`}</MDXTag>
      <h3>
  Gin Lane Media, Senior Technologist - Full Stack Developer
  <br />
  <small>February 2013 — Present, New York, NY, USA (Remote)</small>
      </h3>
      <MDXTag name="p" components={components}>{`Collaborated with designers and business stakeholders to provide full stack
development of performant, mobile friendly, API driven experiences for over a
dozen award winning `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.bestmadeco.com"
        }}>{`e-commerce`}</MDXTag>{`, `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "http://www.365bywholefoods.com"
        }}>{`marketing`}</MDXTag>{`, and `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "http://saturdaymagazine.com"
        }}>{`micro sites`}</MDXTag>{` for
industry leading and startup brands.`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Configured and optimized CI deployment of Rails, Node, and Static
applications to AWS infrastructure.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Lead research and prototype implementation of modern REST API alternatives
like Falcor and GraphQL`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Lead adoption of `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://storybook.js.org/"
          }}>{`Storybook`}</MDXTag>{` as a component library and visual testing
tool.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Maintained high code maintainability and quality through unit tests,
integration tests, code reviews, and linting.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Optimized site performance with techniques such as bundle size reduction,
asset loading orchestration, animation jank debugging`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Lead adoption of BEM and css-in-js CSS organization methodologies.`}</MDXTag>
      </MDXTag>
      <MDXTag name="h4" components={components}>{`Projects`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.gget.com/"
          }}>{`Go Get Em Tiger`}</MDXTag>{` — React / Rails / Solidus`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.relode.com/"
          }}>{`Relode`}</MDXTag>{` — React / Gatsby / Contentful`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://shop.sundaygoods.com/"
          }}>{`Sunday Goods`}</MDXTag>{` — React / Rails / Solidus`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.ge.com/digitalvolcano/"
          }}>{`GE : Digital Volcano`}</MDXTag>{` — Javascript / GSAP / THREE.js`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.ginlane.com"
          }}>{`Gin Lane`}</MDXTag>{` — React / Gatsby / Contentful`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.bestmadeco.com/"
          }}>{`Best Made Co.`}</MDXTag>{` — Ecomm - Rails / Spree`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "http://saturdaysmagazine.com/"
          }}>{`Saturdays Magazine`}</MDXTag>{` — HTML / CSS / jQuery / CoffeeScript`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`365 by Whole Foods Market — React / Node / Falcor`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`La Ligne — Ecomm - React / Rails / Solidus`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`AYR — HTML, CSS, Javascript, Ruby on Rails, `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "http://spreecommerce.org/"
          }}>{`Spreecommerce`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Adidas #mygirls microsite — HTML, CSS, Javascript, PHP, Silex, Tridion`}</MDXTag>
      </MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <h3>
  Hardin Design & Development, Senior Web Developer
  <br />
  <small>March 2012 — February 2013, Madison, WI, USA</small>
      </h3>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "http://bluetreenetwork.com"
          }}>{`Bluetree Network`}</MDXTag>{` — HTML, CSS, Javascript, asp.NET, Transact-SQL`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "http://recplexonline.com"
          }}>{`RecPlex`}</MDXTag>{` HTML, CSS, Javascript, PHP, Zend, MySQL`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "http://foth.com"
          }}>{`Foth`}</MDXTag>{` — HTML, CSS, Javascript, PHP, Wordpress`}</MDXTag>
      </MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <h3>
  Sony Creative Software, User Experience Software Engineer
  <br />
  <small>January 2009 — July 2011, Madison, WI, USA</small>
      </h3>
      <MDXTag name="p" components={components}>{`Senior Software Engineer (July 2011 — November 2011)`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`C# and C++ development and UX design for 7 major releases of media creation
software.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Designed and organized the company’s first ever usability tests.`}</MDXTag>
      </MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <h3>
  University of Wisconsin Athletic Department, Web Developer
  <br />
  <small>September 2008 — January 2009, Madison, WI, USA</small>
      </h3>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`asp.NET web application development.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Visual Basic, asp.NET, Transact-SQL`}</MDXTag>
      </MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <h3>
  Intuit Inc., Software Engineer Intern
  <br />
  <small>May 2008 — August 2008, San Diego, CA, USA</small>
      </h3>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Engineering Intern for TurboTax on Windows,`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Agile software development and User Experience testing.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`C#, .NET, WinForms`}</MDXTag>
      </MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <h3>
  Quad/Graphics Inc., Software Development Trainee
  <br />
  <small>May 2007 — May 2008, Sussex, WI, USA</small>
      </h3>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`asp.NET web application development.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Visual Basic, C#, asp.NET, Transact-SQL`}</MDXTag>
      </MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h2" components={components}>{`Education`}</MDXTag>
      <MDXTag name="p" components={components}>{`B.S. in Computer Sciences
2008 University of Wisconsin–Madison`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    